import { useUsersContext } from "context/usersContext";
import localforage from "localforage";
import { useNavigate } from "react-router-dom";

function useAuth() {
  // const { users, loggedinuser } = useUsersContext();
  return "loggedinuser?.token";
}

function useLogout() {
  // const clear = useUsersContext()[2];
  // const navigate = useNavigate();

  return () => {
    localforage.clear();
    // clear();
    // navigate("/auth/sign-in");
  };
}

export { useAuth, useLogout };
