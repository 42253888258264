import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import env from "config";
import CategoryAccordion from "./CategoryAccordion";
import { PromptsContext } from "context/promptsContext";
import Data from "data/contacts";

const Prompts = ({ userId }) => {
	const { promptsData } = useContext(PromptsContext);
    const { ChatSubmit } = Data();

	return (
		<>
			<div className="chat-sidebar__search-results">
				<h2 className="promptsHeading">Explore Prompts</h2>
				{promptsData && (
					<CategoryAccordion
						promptsCategories={promptsData.prompt_category}
						promptsSubCategories={promptsData.prompt_sub_category}
						prompts={promptsData.prompt}
						userId={userId}
						ChatSubmit={ChatSubmit}
					/>
				)}
			</div>
		</>
	);
};

export default Prompts;
