import React, { useEffect, useRef, useState } from "react";
import "./styles/main.css";
import ChatInput from "./components/ChatInput";
import ChatSidebar from "./components/ChatSidebar";
import Icon from "components/Icon";
import Convo from "./components/Convo";
import { useUsersContext } from "context/usersContext";
import Header from "./components/Header";
import Prompts from "./components/Prompts";
import { useLocation } from "react-router-dom";
import axios from "axios";
import localforage from "localforage";
import env from "config";
import LimitModal from "components/LimitModal";

const Chat = ({ match, history }) => {
  const {
    users,
    setUserAsUnread,
    addNewMessage,
    showLeftSidebar,
    hideLeftSidebar,
    isActiveSide,
    loggedinuser,
  } = useUsersContext();

  const { pathname } = useLocation();
  const loc_id = loggedinuser._id;
  const loc_name = loggedinuser.loc_name;
  const username = loggedinuser.username;

  const userId = match.params.id;
  let user = users.filter((user) => user.id === userId)[0];
  // if (user === undefined) {
  //   const redirectUrl = `/${loc_id}/${loc_name}/${username}`;
  //   history.push(redirectUrl);
  // }

  const BASE_URL = `${env.API_URL}/v1`;
  const lastMsgRef = useRef(null);
  const [showAttach, setShowAttach] = useState(false);
  const [showEmojis, setShowEmojis] = useState(false);
  const [showProfileSidebar, setShowProfileSidebar] = useState(false);
  const [showSearchSidebar, setShowSearchSidebar] = useState(false);
  const [newMessage, setNewMessage] = useState("");
  const [footerHeight, setFooterHeight] = useState("64px");
  const [goBottomBtn, setGoBottomBtn] = useState("120px");
  const [isLimitModalOpen, setIsLimitModalOpen] = useState(false);

  useEffect(() => {
    console.log(user);
    if (!user) {
      const redirectUrl = `/`;
      history.push(redirectUrl);
    } else {
      scrollToLastMsg();
      setUserAsUnread(user.id);
    }
  }, []);

  useEffect(() => {
    if (user) {
      scrollToLastMsg();
    }
  }, [users, user]);

  useEffect(() => { }, [isActiveSide]);

  useEffect(() => {
    if (footerHeight) {
      const newHeight = parseInt(footerHeight.replace(/\D/g, "")) + 56 + "px";
      setGoBottomBtn(newHeight);
      console.log(footerHeight);
    }
  }, [footerHeight]);

  const openSidebar = (cb) => {
    setShowProfileSidebar(false);
    setShowSearchSidebar(false);
    hideLeftSidebar();

    // call callback fn
    cb(true);
  };

  const closeSidebar = () => {
    setShowSearchSidebar(false);
    showLeftSidebar();
  };

  const scrollToLastMsg = () => {
    if (lastMsgRef.current) {
      lastMsgRef.current.scrollIntoView();
    }
  };
  const closeLimit_withRedirect = () => {
    setIsLimitModalOpen(false);
    window.open('https://go.maybetech.com/personalised-ai-upgrade', '_self');
  };

  const closeLimitModel = () => {
    setIsLimitModalOpen(false);
    window.open('https://go.maybetech.com/masterclass', '_self');
  };
  const submitNewMessage = async () => {
    const calendar_res = await localforage.getItem("calendar_res");

    if (calendar_res !== null && calendar_res >= 10) {
      setIsLimitModalOpen(true);
      return false;
    }

    const user_data = await localforage.getItem("user_data");
    if (user_data) {
      const userUpdated = await axios
        .put(
          `${BASE_URL}/user/updateuser?email=${encodeURIComponent(
            user_data?.email
          )}`,
          {}
        )
        .then(async function (response) {
          await localforage.setItem(
            "calendar_res",
            response.data.data.calendar_res
          );

          return response.data;
        })
        .catch(function (error) {
          return error;
        });

      if (userUpdated.success) {
        addNewMessage(user.id, newMessage);
        setNewMessage("");
        scrollToLastMsg();
      } else {
        console.log(userUpdated?.error);
      }
    } else {
      addNewMessage(user.id, newMessage);
      setNewMessage("");
      scrollToLastMsg();
    }
  };

  return (
    <div className="chat">
      <div className="chat__body">
        {/* <div className="chat__bg"></div> */}
        <div className="chat__content">
          {user && (
            <Convo
              userId={userId}
              lastMsgRef={lastMsgRef}
              messages={user.messages}
              newMessage={newMessage}
              setNewMessage={setNewMessage}
              submitNewMessage={submitNewMessage}
            />
          )}
        </div>
        <footer className="chat__footer">
          <button
            style={{ bottom: goBottomBtn }}
            className="chat__scroll-btn"
            aria-label="scroll down"
            onClick={scrollToLastMsg}
          >
            <Icon id="downArrow" />
          </button>
          <ChatInput
            showEmojis={showEmojis}
            setShowEmojis={setShowEmojis}
            showAttach={showAttach}
            setShowAttach={setShowAttach}
            newMessage={newMessage}
            setNewMessage={setNewMessage}
            submitNewMessage={submitNewMessage}
            setFooterHeight={setFooterHeight}
            footerHeight={footerHeight}
          />
        </footer>
      </div>

      <LimitModal
        isModalOpen={isLimitModalOpen}
        closeModalWithRedirect={closeLimit_withRedirect}
        justCloseIt={closeLimitModel}
      />
    </div>
  );
};

export default Chat;
