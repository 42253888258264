import React, { useState } from "react";
import "./styles/main.css";
import "./styles/form.css";
import maybe from "assets/images/maybe.png";
import axios from "axios";
import { useUsersContext } from "context/usersContext";
import Data from "data/contacts";
import { useHistory } from "react-router-dom";
import env from "config";
import localforage from "localforage";
import LimitModal from "components/LimitModal";

const Home = () => {
  const BASE_URL = `${env.API_URL}/v1`;

  const { users, setUserAsUnread, addNewMessage, refresh, showToast } =
    useUsersContext();
  const history = useHistory();
  // setSIgnUp
  const [SIgnUp, setSIgnUp] = useState(true);
  const [refreshAgain, setRefreshAgain] = useState();
  const [isLimitModalOpen, setIsLimitModalOpen] = useState(false);
  const { ChatSubmit } = Data();
  const darkTheme = document.body.classList.contains("dark-theme");
  const {
    users: contacts,
    locValid,
    setlocValid,
    loggedinuser,
    updateloggedinuser,
  } = useUsersContext();
  const pathname = window.location.pathname;
  const loc_id = loggedinuser._id;
  const loc_name = loggedinuser.loc_name;
  const username = loggedinuser.username;

  const handleSubmit = async (e) => {
    e.preventDefault();

    var payload = {
      facebook: [e.target.facebook.value],
      instagram: [e.target.instagram.value],
      profile_id: "v2/" + loc_id,
      profile_name: loc_name,
    };
    const response = await axios
      .post(
        "https://3yv7611xa1.execute-api.eu-west-2.amazonaws.com/Prod/haumea/api/profiles",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        window.location.reload();
      })
      .catch((error) => { });
  };

  const closeLimit_withRedirect = () => {
    setIsLimitModalOpen(false);
    window.open('https://go.maybetech.com/personalised-ai-upgrade', '_self');
  };

  const closeLimitModel = () => {
    setIsLimitModalOpen(false);
    window.open('https://go.maybetech.com/masterclass', '_self');
  };
  const handleChatSubmit = async (e) => {
    e.preventDefault();
    const name = e.target.name.value;
    const payload = {
      // name: name,
      locationId: loggedinuser._id,
      username: username,
    };

    // request limit working
    const calendar_res = await localforage.getItem("calendar_res");

    if (calendar_res !== null && calendar_res >= 10) {
      setIsLimitModalOpen(true);
      return false;
    }
    
    const temp = await ChatSubmit(payload);

    if (!temp?.response) {
      refresh(temp);

      const chatUrl = `/chat/${temp.id}`;
      setUserAsUnread(temp.id);
      history.push(chatUrl);
    } else {
      showToast("Something went wrong!");
    }
  };

  const handleRegisterUser = async (e) => {
    e.preventDefault();
    // return 0;
    const payload = {
      username: e.target.username.value,
      loc_name: e.target.loc_name.value,
      email: e.target.email.value,
      role: "demo_user",
      // contact: e.target.contact.value,
    };

    await axios
      .post(BASE_URL + "/auth", payload)
      .then(async function (response) {
        console.log(response.data, "sending data to save in localforage");
        updateloggedinuser(response.data.data);
        return response;
      })
      .catch(function (error) {
        return error;
      });
  };
  const handleLoginUser = async (e) => {
    e.preventDefault();
    const payload = {
      email: e.target.email.value,
      // password: e.target.password.value,
      role: "demo_user",
    };

    await axios
      .post(BASE_URL + "/auth/login", payload)
      .then(async function (response) {
        console.log(response.data, "data");
        updateloggedinuser(response.data.data);
        return response;
      })
      .catch(function (error) {
        return error;
      });
  };
  console.log(loggedinuser, "loggedinuser");
  return (
    <div className="home">
      {loggedinuser.email ? (
        <>
          <div className="home__img-wrapper ">
            {/* <img src={darkTheme ? maybe : maybe} alt="" className="home__img" /> */}
            <h1 className="home__title">
              {" "}
              Do your best work faster with personalised AI
            </h1>
            <button
              className="btn btn-chat btn-sm"
              aria-label="New chat"
              style={{
                marginTop: "28px",
                width: "100%",
                padding: "20px",
                fontSize: "20px",
              }}
              onClick={(e) => handleChatSubmit(e)}
            >
              Start New Chat
            </button>
          </div>
        </>
      ) : (
        <>
          {SIgnUp ? (
            <div className="modal-content">
              <div className="modal-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="60"
                  height="50"
                  viewBox="0 0 60 50"
                  fill="none"
                >
                  <path
                    d="M27.5 12.4975C33.4875 14.325 38.175 19.015 39.9975 25C41.83 19.0125 46.5175 14.3275 52.5 12.5C46.5175 10.6725 41.83 5.9875 39.9975 0C38.175 5.985 33.4875 10.67 27.5 12.4975ZM45 29.9975C48.5925 31.0925 51.405 33.9075 52.4975 37.5C53.5975 33.9075 56.41 31.095 60 30C56.41 28.9025 53.5975 26.0925 52.4975 22.5C51.405 26.09 48.5925 28.9025 45 29.9975ZM30 43.75C32.995 44.6625 35.3375 47.0075 36.2475 50C37.1625 47.005 39.5075 44.6625 42.5 43.75C39.5075 42.835 37.165 40.495 36.2475 37.5C35.3375 40.4925 32.995 42.835 30 43.75ZM14.9975 12.5C12.81 19.6825 7.1775 25.3125 0 27.5025C7.19 29.7025 12.81 35.3225 15.01 42.5125C17.1975 35.3275 22.83 29.7025 30 27.5025C22.83 25.3175 17.1975 19.685 14.9975 12.5Z"
                    fill="#0D0D0D"
                  />
                </svg>
              </div>
              <p className="mb-4 modalText mt-10">
                To use Maybe* AI let us know who you are:{" "}
              </p>
              <form className="space-y-4" onSubmit={handleRegisterUser}>
                <div style={{ display: "flex", gap: "18px" }}>
                  <input
                    type="text"
                    name="username"
                    placeholder="User Name"
                    className="flex-1 modalInputs"
                  />
                  <input
                    type="text"
                    placeholder="Business Name"
                    name="loc_name"
                    className="flex-1 modalInputs"
                  />
                </div>
                <div>
                  <input
                    type="text"
                    placeholder="Email"
                    name="email"
                    className="w-full modalInputs"
                  />
                </div>
                <button type="submit" className="w-full modalBtn">
                  Next
                </button>
              </form>
            </div>
          ) : (
            <div className="modal-content">
              <div className="modal-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="60"
                  height="50"
                  viewBox="0 0 60 50"
                  fill="none"
                >
                  <path
                    d="M27.5 12.4975C33.4875 14.325 38.175 19.015 39.9975 25C41.83 19.0125 46.5175 14.3275 52.5 12.5C46.5175 10.6725 41.83 5.9875 39.9975 0C38.175 5.985 33.4875 10.67 27.5 12.4975ZM45 29.9975C48.5925 31.0925 51.405 33.9075 52.4975 37.5C53.5975 33.9075 56.41 31.095 60 30C56.41 28.9025 53.5975 26.0925 52.4975 22.5C51.405 26.09 48.5925 28.9025 45 29.9975ZM30 43.75C32.995 44.6625 35.3375 47.0075 36.2475 50C37.1625 47.005 39.5075 44.6625 42.5 43.75C39.5075 42.835 37.165 40.495 36.2475 37.5C35.3375 40.4925 32.995 42.835 30 43.75ZM14.9975 12.5C12.81 19.6825 7.1775 25.3125 0 27.5025C7.19 29.7025 12.81 35.3225 15.01 42.5125C17.1975 35.3275 22.83 29.7025 30 27.5025C22.83 25.3175 17.1975 19.685 14.9975 12.5Z"
                    fill="#0D0D0D"
                  />
                </svg>
              </div>
              <p className="mb-4 modalText mt-10">
                To use Maybe* AI let us know who you are:{" "}
              </p>
              <form className="space-y-4" onSubmit={handleLoginUser}>
                <div>
                  <input
                    type="text"
                    placeholder="Email"
                    name="email"
                    className="w-full modalInputs"
                  />
                </div>
                <button type="submit" className="w-full modalBtn">
                  Next
                </button>
              </form>
            </div>
          )}
        </>
      )}

      <LimitModal
        isModalOpen={isLimitModalOpen}
        closeModalWithRedirect={closeLimit_withRedirect}
        justCloseIt={closeLimitModel}
      />
    </div>
  );
};

export default Home;
