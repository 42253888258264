import React, { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Loader from "./components/Loader";
import Home from "./pages/Home";
import Sidebar from "components/Sidebar";
import Chat from "pages/Chat";
import { useUsersContext } from "context/usersContext";
import maybeLogo from "./assets/icons/maybe-logo.webp";

const userPrefersDark =
  window.matchMedia &&
  window.matchMedia("(prefers-color-scheme: dark)").matches;

function App() {
  const [appLoaded, setAppLoaded] = useState(false);
  const [startLoadProgress, setStartLoadProgress] = useState(false);
  const {
    users: contacts,
    locValid,
    setlocValid,
    loggedinuser,
  } = useUsersContext();
  useEffect(() => {
    if (userPrefersDark) document.body.classList.add("dark-theme");
    stopLoad();
  }, []);

  const stopLoad = () => {
    setStartLoadProgress(true);
    setTimeout(() => setAppLoaded(true), 3000);
  };

  if (!appLoaded) return <Loader done={startLoadProgress} />;

  return (
    <>
      <div className="topHeader">
        <div className="logo">
          <img src={maybeLogo} alt="" />
        </div>
        <div className="groupBtn">
          <a
            href="https://go.maybetech.com/personalised-ai-upgrade"
            className="black"
            target="_blank"
          >
            Personlise my results
          </a>
          <a href="https://v2.maybetech.com/" target="_blank">
            Log in
          </a>
        </div>
      </div>
      <div className="app">
        <p className="app__mobile-message"> Only available on desktop 😊. </p>
        <Router>
          <div className="app-content">
            {loggedinuser.email ? <Sidebar /> : <></>}
            <Switch>
              <Route path="/chat/:id" component={Chat} />
              <Route path="/" component={Home} />
              <Route path="/home" component={Home} />
            </Switch>
          </div>
        </Router>
      </div>
    </>
  );
}

export default App;
