import { createContext, useContext, useEffect, useState } from "react";
import contacts from "data/contacts";
// import { useSocketContext } from "./socketContext";
import getRandomSentence from "utils/getRandomSentence";
import axios from "axios";
import env from "config";
import { useHistory } from "react-router-dom";
import Toast from "components/Toast";
import localforage from "localforage";
import { useAppServices } from "hook/services";

const UsersContext = createContext();

const useUsersContext = () => useContext(UsersContext);
const BASE_URL = `${env.API_URL}/v1`;
const CHAT_API = `${env.Chat_API}`;
const CHAT_AUTH = `${env.Chat_Auth}`;

const UsersProvider = ({ children }) => {
  // const socket = useSocketContext();
  const Service = useAppServices();

  const [isActiveSide, setIsActiveSide] = useState(true);
  const { data, processing, handleRefreshData } = contacts();
  const [users, setUsers] = useState(data);
  const [userTyping, setUserTyping] = useState({});
  const [fromPromptMsg, setFromPromptMsg] = useState({});
  const [loggedinuser, setloggedinUser] = useState({});

  const [showExploreContent, setShowExploreContent] = useState(false);

  // Function to toggle showExploreContent
  const toggleExploreContent = () => {
    setShowExploreContent((prev) => !prev);
  };

  const history = useHistory();

  const [locValid, setlocValid] = useState(data);

  const pathname = window.location.pathname;
  const loc_id = loggedinuser._id;
  const loc_name = loggedinuser.loc_name;
  const username = loggedinuser.username;
  const [toast, setToast] = useState({ message: "", isVisible: false });

  const showToast = (message) => {
    setToast({ message, isVisible: true });
    setTimeout(() => setToast({ message: "", isVisible: false }), 3000); // Auto-hide after 3 seconds
  };

  const hideToast = () => {
    setToast({ message: "", isVisible: false });
  };

  const hideLeftSidebar = () => {
    setIsActiveSide(false);
  };

  const showLeftSidebar = () => {
    setIsActiveSide(true);
  };

  const handleDeleteContact = async (contactId) => {
    const response = await axios.get(`${BASE_URL}/chat/delete?id=${contactId}`);
    return response;
  };

  const _updateUserProp = (userId, prop, value) => {
    setUsers((users) => {
      const usersCopy = [...users];
      let userIndex = users.findIndex((user) => user.id === userId);
      const userObject = usersCopy[userIndex];
      usersCopy[userIndex] = { ...userObject, [prop]: value };
      setUserTyping(usersCopy);
      return usersCopy;
    });
  };

  const setUserAsTyping = (data) => {
    const { userId } = data;
    setUserTyping(true);
    _updateUserProp(userId, "typing", true);
  };

  const setUserAsNotTyping = (data) => {
    const { userId } = data;
    setUserTyping(false);
    _updateUserProp(userId, "typing", false);
  };
  const setOnFromPrompt = (userId) => {
    setFromPromptMsg(true);
    _updateUserProp(userId, "fromPrompt", true);
  };

  const setOffFromPrompt = (data) => {
    const { userId } = data;
    setFromPromptMsg(false);
    _updateUserProp(userId, "fromPrompt", false);
  };
  const fetchMessageResponse = async (data) => {
    // setUsers((users) => {
    const { userId, response } = data;
    setUserAsTyping(data);
    let msg_data = JSON.stringify({
      conversation_id: userId,
      user_name: username,
      prompt: response.content,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${CHAT_API}/chat/message`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${CHAT_AUTH}`,
      },
      data: msg_data,
    };
    var msg = "";
    await axios
      .request(config)
      .then((response) => {
        msg = response.data.data;
        setUserAsNotTyping(data);
      })
      .catch(async (error) => {
        if (error.response?.status == 400) {
          await axios
            .get(BASE_URL + "/chat/delete?id=" + userId)
            .then(async function (response) {
              window.location.href = `/`;
            })
            .catch(function (error) {});
        }
      });

    let userIndex = users.findIndex((user) => user.id === userId);
    const usersCopy = JSON.parse(JSON.stringify(users));
    const newMsgObject = {
      content: msg,
      sender: userId,
      time: new Date().toLocaleTimeString(),
      status: null,
    };
    const payload = {
      message: newMsgObject,
      conversation_id: userId,
    };
    await axios
      .post(BASE_URL + "/message/response", { ...payload })
      .then(async function (response) {})
      .catch(function (error) {});
    var key = Object.keys(usersCopy[userIndex].messages);
    key = key[0];
    usersCopy[userIndex].messages[key].push(newMsgObject);
    setUsers(usersCopy);
    // return usersCopy;
    // });
  };
  const refresh = (newdata) => {
    // setUsers(newdata);

    users.unshift(newdata);
    setUsers(users);
  };
  const checkLocation = async (user_data) => {
    console.log(user_data, "checkLocationcheckLocation");
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `https://3yv7611xa1.execute-api.eu-west-2.amazonaws.com/Prod/haumea/api/profiles/${user_data.data._id}`,
      headers: {},
    };

    await axios
      .request(config)
      .then((response) => {
        setlocValid(true);
      })
      .catch((error) => {
        window.location.href = `https://connect.maybetech.com/?profile_id=${user_data.data._id}&profile_name=${user_data.data.loc_name}&username=${user_data.data.username}&type=trial_chat`;
        setlocValid(false);
        // setlocValid(true);
      });
  };
  const userData = async () => {
    const user_data = await localforage.getItem("user_data");
    if (user_data) {
      setloggedinUser(user_data);
      return {
        status: true,
        data: user_data,
      };
    } else {
      setloggedinUser({});
      return {
        status: false,
      };
    }
  };
  useEffect(() => {
    setUsers(data);
  }, [processing]);
  const onLoad = async () => {
    const check_user = await userData();
    console.log(data, "onLoadonLoadonLoad");
    if (check_user.status) {
      checkLocation(check_user);
    } else {
      // window.location.href = "http://localhost:4040/signin";
      // Window.location(`/signin`);
      // alert("not loggedin");
    }
  };

  useEffect(() => {
    if (Object.keys(loggedinuser).length !== 0) {
      console.log(
        loggedinuser,
        "loggedinuserloggedinuserloggedinuserloggedinuserloggedinuser"
      );
    }
  }, [loggedinuser]);

  useEffect(() => {
    onLoad();
  }, []);

  const setUserAsUnread = (userId) => {
    _updateUserProp(userId, "unread", 0);
  };

  const updateloggedinuser = async (user_data) => {
    setloggedinUser(user_data);
    let data = [];
    data["data"] = user_data;
    checkLocation(data);
    await localforage.setItem("user_data", user_data);
    return true;
  };

  const addNewMessage = async (userId, message, prompt) => {
    let userIndex = users.findIndex((user) => user.id === userId);
    var usersCopy = [...users];
    const newMsgObject = {
      content: message,
      sender: null,
      time: new Date().toLocaleTimeString(),
      status: "delivered",
    };
    var key = Object.keys(usersCopy[userIndex].messages);
    key = key[0];
    usersCopy[userIndex].messages[key].push(newMsgObject);
    const payload = {
      message: newMsgObject,
      conversation_id: userId,
    };

    await axios
      .post(BASE_URL + "/message", { ...payload })
      .then(async function (response) {
        if (response.data.chatupdated) {
          usersCopy[userIndex].name = message;
          setUsers(usersCopy);
        } else {
          setUsers(usersCopy);
        }
        if (prompt != undefined) {
          fetchMessageResponse({
            userId: userId,
            response: { content: prompt },
          });
        } else {
          fetchMessageResponse({
            userId: userId,
            response: { content: message },
          });
        }
      })
      .catch(function (error) {});
  };

  return (
    <UsersContext.Provider
      value={{
        users,
        loggedinuser,
        updateloggedinuser,
        setUsers,
        locValid,
        setlocValid,
        setUserAsUnread,
        addNewMessage,
        processing,
        refresh,
        userTyping,
        hideLeftSidebar,
        showLeftSidebar,
        isActiveSide,
        handleDeleteContact,
        toast,
        showToast,
        hideToast,
        showExploreContent,
        toggleExploreContent,
        setOnFromPrompt,
        setOffFromPrompt,
        fromPromptMsg,
      }}
    >
      {children}
      {toast.isVisible && <Toast message={toast.message} onClose={hideToast} />}
    </UsersContext.Provider>
  );
};

export { useUsersContext, UsersProvider };
