import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import env from "config";
import localforage from "localforage";
// import { useUsersContext } from "context/usersContext";

const Data = () => {
  const BASE_URL = `${env.API_URL}/v1`;
  const APP_ENV = env.APP_ENV;
  const [data, setData] = useState([]);
  const [processing, setProcessing] = useState(true);

  const GetChats = async () => {
    const user_data = await localforage.getItem("user_data");
    setProcessing(true);
    try {
      const response = await axios.get(
        `${BASE_URL}/chat/filter?locationId=${user_data?._id}`
      );
      console.log(response, "GetChatsGetChats");
      setData(response.data.data);
    } catch (error) {
      console.error(error);
    } finally {
      setProcessing(false);
    }
  };

  const ChatSubmit = async (payload, fromPrompt = false) => {
    const user_data = await localforage.getItem("user_data");
    console.log(user_data, "user_datauser_data");
    payload.env = APP_ENV;
    
    if (user_data) {
      const userUpdated = await axios
        .put(
          `${BASE_URL}/user/updateuser?email=${encodeURIComponent(
            user_data?.email
          )}`,
          {}
        )
        .then(async function (response) {
          console.log(
            response.data.data,
            "response.dataresponse.dataresponse.data"
          );
          await localforage.setItem(
            "calendar_res",
            response.data.data.calendar_res
          );

          return response.data;
        })
        .catch(function (error) {
          return error;
        });

      if (userUpdated.success) {
        return await axios
          .post(BASE_URL + "/chat", {
            ...payload,
          })
          .then(async function (response) {
            var chat_data = {};
            chat_data["Welcome " + response.data.data.username] = [];
            const chat = {
              id: response.data.data.conversation_id,
              name: "",
              whatsapp_name: response.data.data.username,
              messages: chat_data,
              welcome_array: "Welcome " + response.data.data.username,
              fromPrompt: fromPrompt,
            };

            return chat;
          })
          .catch(function (error) {
            return error;
          });
      } else {
        console.log(userUpdated?.error);
      }
    } else {
      return await axios
        .post(BASE_URL + "/chat", {
          ...payload,
        })
        .then(async function (response) {
          var chat_data = {};
          chat_data["Welcome " + response.data.data.username] = [];
          const chat = {
            id: response.data.data.conversation_id,
            name: "",
            whatsapp_name: response.data.data.username,
            messages: chat_data,
            welcome_array: "Welcome " + response.data.data.username,
          };

          return chat;
        })
        .catch(function (error) {
          return error;
        });
    }
  };

  useEffect(async () => {
    GetChats();
  }, []);

  return {
    data,
    processing,
    handleRefreshData: GetChats,
    ChatSubmit,
    GetChats,
  };
};

export default Data;
