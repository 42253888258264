import Icon from "components/Icon";
import { useUsersContext } from "context/usersContext";
import { useLocation, useHistory } from "react-router-dom";
import React, { useRef, useState } from "react";
import localforage from "localforage";
import LimitModal from "components/LimitModal";

//  accordionitem component
const AccordionItem = ({ question, prompts, isOpen, onClick, sub_cate_id, userId, ChatSubmit }) => {
    const history = useHistory();
    const { addNewMessage, setUserAsUnread, refresh, showToast, loggedinuser, toggleExploreContent, setOnFromPrompt } = useUsersContext();
    const { pathname } = useLocation();
    const [isLimitModalOpen, setIsLimitModalOpen] = useState(false);
    const loc_id = loggedinuser._id;
    const loc_name = loggedinuser.loc_name;
    const username = loggedinuser.username;

    const closeLimit_withRedirect = () => {
        setIsLimitModalOpen(false);
        window.open('https://go.maybetech.com/personalised-ai-upgrade', '_self');
    };

    const closeLimitModel = () => {
        setIsLimitModalOpen(false);
        window.open('https://go.maybetech.com/masterclass', '_self');
    };

    const handleSubmit = async (e, user_msg, prompt) => {
        e.preventDefault();
        console.log(userId,'userIduserIduserId');
        setOnFromPrompt(userId);
        const payload = {
            locationId: loc_id,
            username: username,
        };

        // request limit working
        const calendar_res = await localforage.getItem("calendar_res");

        if (calendar_res !== null && calendar_res >= 10) {
            setIsLimitModalOpen(true);
            return false;
        }
        toggleExploreContent();

        const temp = await ChatSubmit(payload, true);

        if (!temp?.response) {
            refresh(temp);

            const chatUrl = `/chat/${temp.id}`;
            setUserAsUnread(temp.id);
            history.push(chatUrl);

            addNewMessage(temp.id, user_msg, prompt);
        } else {
            showToast("Something went wrong!");
        }
    };
    const contentHeight = useRef();
    return (
        <div className="wrapper subCategoryWrapper">
            <button
                className={`question-container ${isOpen ? "active" : ""}`}
                onClick={onClick}
            >
                <p className="question-content">{question}</p>
                {isOpen ?
                    <Icon id="upArrow" className="chat__input-icon" /> :
                    <Icon id="downArrow" className="chat__input-icon" />}
                {/* <RiArrowDropDownLine className={`arrow ${isOpen ? "active" : ""}`} /> */}
            </button>

            <div
                ref={contentHeight}
                className="answer-container"
                style={
                    isOpen
                        ? { height: "fit-content" }
                        : { height: "0px" }
                }
            >
                <div className="answer-content">
                    {prompts.map((item, index) => (
                        <>
                            {item.sub_category == sub_cate_id ?
                                <div className="prompt_box_sidebar"
                                    onClick={(event) => handleSubmit(event, item.title, item.prompt_value)} >
                                    <div className="prompt_text">
                                        {item.title}
                                    </div>
                                    <Icon id="send" className="chat__input-icon prompt_icon" />
                                </div>
                                :
                                ''}
                        </>
                    ))}
                </div>
            </div>

            <LimitModal
                isModalOpen={isLimitModalOpen}
                closeModalWithRedirect={closeLimit_withRedirect}
                justCloseIt={closeLimitModel}
            />
        </div>
    );
};

const SubCategoryAccordion = ({ subCategories, cat_id, userId, prompts, ChatSubmit }) => {
    const [activeIndex, setActiveIndex] = useState(null);

    const handleItemClick = (index) => {
        setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    return (
        <>
            {subCategories.map((item, index) => (
                <>
                    {item.category_id == cat_id ?
                        <AccordionItem
                            key={index}
                            question={item.name}
                            prompts={prompts}
                            sub_cate_id={item._id}
                            isOpen={activeIndex === index}
                            userId={userId}
                            onClick={() => handleItemClick(index)}
                            ChatSubmit={ChatSubmit}
                        />
                        :
                        <></>
                    }
                </>
            ))}
        </>
    );
};

export default SubCategoryAccordion;
