import React, { useState, useEffect, useRef } from "react";
import Icon from "components/Icon";

const ChatInput = ({
  showAttach,
  setShowAttach,
  showEmojis,
  setShowEmojis,
  newMessage,
  setNewMessage,
  submitNewMessage,
  setFooterHeight,
  footerHeight,
  setGoBottomBtn,
}) => {
  const [inputHeight, setInputHeight] = useState("64px");
  const textareaRef = useRef(null);

  const detectEnterPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      submitNewMessage();
    }
  };

  useEffect(() => {
    const adjustHeight = () => {
      // Reset height to shrink if content is removed
      textareaRef.current.style.height = '64px';

      // Set height based on scrollHeight, but not more than the max height
      const maxHeight = 200; // Set your maximum height here
      const newHeight = Math.min(textareaRef.current.scrollHeight, maxHeight);
      textareaRef.current.style.height = `${newHeight}px`;
      setFooterHeight(`${newHeight}px`);
    };

    // Adjust height on message change
    adjustHeight();

    // Adjust height on window resize
    window.addEventListener('resize', adjustHeight);
    return () => {
      window.removeEventListener('resize', adjustHeight);
    };
  }, [newMessage]);

  return (
    <div className="chat__input-wrapper" style={{ height: footerHeight }}>
      {/* ... other components ... */}
      <div className="pos-rel" style={{ marginBottom: inputHeight }}>
        {/* ... attach button and emojis ... */}
      </div>
      <textarea
        ref={textareaRef}
        className="chat__input"
        placeholder="Message Personalised AI"
        value={newMessage}
        onChange={(e) => setNewMessage(e.target.value)}
        onKeyDown={detectEnterPress}
        style={{ overflowY: 'auto', position: 'absolute', bottom: "10px", width: "100%", left: 0 }}
      />
      <button aria-label="Send message" onClick={submitNewMessage} className="sendChatBtn">
        <Icon id="sendChat" className="chat__input-icon" />
      </button>
    </div>
  );
};

export default ChatInput;
