import React, { useState } from "react";
import "./style.css";
import Data from "data/contacts";
import { useLocation } from "react-router-dom";
import { useUsersContext } from "context/usersContext";
import LimitModal from "components/LimitModal";
import { useHistory } from "react-router-dom";
import settings from "assets/images/cog.svg";
import Icon from "components/Icon";
import Tooltip from "components/Tooltip";
import localforage from "localforage";

function ChatModal({ processing, onExplorePromots, showExploreContent }) {
  const history = useHistory();
  const location = useLocation();
  const shouldShowExploreButton = location.pathname.includes("/chat/");
  const { setUserAsUnread, refresh, showToast, loggedinuser } =
    useUsersContext();
  const [isShowExploreBtn, setIsShowExploreBtn] = useState(false);
  const [isExploreActive, setIsExploreActive] = useState(false);
  const [isLimitModalOpen, setIsLimitModalOpen] = useState(false);
  const { ChatSubmit } = Data();

  const { pathname } = useLocation();
  const loc_id = loggedinuser._id;
  const loc_name = loggedinuser.loc_name;
  const username = loggedinuser.username;

  const closeLimit_withRedirect = () => {
    setIsLimitModalOpen(false);
    window.open('https://go.maybetech.com/personalised-ai-upgrade', '_self');
  };

  const closeLimitModel = () => {
    setIsLimitModalOpen(false);
    window.open('https://go.maybetech.com/masterclass', '_self');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      locationId: loc_id,
      username: username,
    };
    
    // request limit working
    const calendar_res = await localforage.getItem("calendar_res");

    if (calendar_res !== null && calendar_res >= 10) {
      setIsLimitModalOpen(true);
      return false;
    }

    const temp = await ChatSubmit(payload);

    if (!temp?.response) {
      refresh(temp);

      const chatUrl = `/chat/${temp.id}`;
      setUserAsUnread(temp.id);
      history.push(chatUrl);
      setIsShowExploreBtn(true);
    } else {
      setIsShowExploreBtn(false);
      showToast("Something went wrong!");
    }
    if (isExploreActive) onExplorePromots(false);
  };

  const handleConnect = (e) => {
    e.preventDefault();
    window.location.href = `https://connect.maybetech.com/?profile_id=${loggedinuser._id}&profile_name=${loggedinuser.loc_name}&username=${loggedinuser.username}&type=trial_chat`;
  };

  const handleExplorePromots = (e) => {
    e.preventDefault();
    onExplorePromots();
  };

  return (
    <div className="chat-dropdown">
      <div>
        <button
          className={`btn btn-newChat ${
            !showExploreContent ? "activeBtn" : ""
          }`}
          disabled={processing}
          aria-label="New chat"
          style={{ width: "115px" }}
          onClick={handleSubmit}
        >
          New Chat
        </button>

        {/* {shouldShowExploreButton && ( */}
        {/* <Tooltip text="Please open any chat first!" show={!shouldShowExploreButton} className={!shouldShowExploreButton ? "show-tooltip" : ""}> */}
        <button
          className={`btn btn-explore ${showExploreContent ? "activeBtn" : ""}`}
          aria-label="New chat"
          style={{ width: "115px" }}
          onClick={handleExplorePromots}
        >
          {showExploreContent ? (
            <Icon id="close" />
          ) : (
            <Icon id="exp_dots" className="exp-dots" />
          )}
          Explore
        </button>
        {/* </Tooltip> */}
        {/* )} */}
      </div>
      <span style={{ cursor: "pointer" }} onClick={(e) => handleConnect(e)}>
        <Icon id="cog" />
      </span>

      <LimitModal
        isModalOpen={isLimitModalOpen}
        closeModalWithRedirect={closeLimit_withRedirect}
        justCloseIt={closeLimitModel}
      />
    </div>
  );
}

export default ChatModal;
